import {Box, Paper, Typography, useTheme} from "@mui/material";
import React, {useMemo, useRef, useState} from "react";
import topology from './world-topo.json';
import * as topojson from 'topojson-client';
import codesMap from './countries-iso-code-map.json';
import {Graticule, Mercator} from "@visx/geo";
import {scaleQuantize} from "@visx/scale";
import ResizeListener from "../../utils/ResizeListener";
import {defaultStyles, useTooltip, useTooltipInPortal} from "@visx/tooltip";
import {toHumanPercentage} from "../../utils/numbers";
import {THEMES} from "../../constants";

export default function WorldMap({ title, data, margin, filters, ...rest }) {
  const theme = useTheme();
  const [visWidth, setVisWidth] = useState(450);
  const [visHeight, setVisHeight] = useState(225);
  const visContainerRef = useRef(null);
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip();
  const { TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
  };

  const scale = useMemo(
    () => (visWidth / 630) * 100,
    [visWidth]
  );
  const centerX = useMemo(
    () => visWidth / 2,
    [visWidth]
  );
  const centerY = useMemo(
    () => visHeight / 2,
    [visHeight]
  );

  const calculateTooltipData = (event) => {
    return JSON.parse(event.target.getAttribute('data-feature'));
  };

  const background = theme.palette.background.paper;
  let world= topojson.feature(topology, topology.objects.units);
  world.features = world.features.map(f => {
    return {
      ...f,
      properties: {
        ...f.properties,
        usersPercentage: data.find(d => d.label === codesMap[f.id])?.percentage ?? 0,
        totalUsers: data.find(d => d.label === codesMap[f.id])?.value ?? 0,
      }
    };
  })

  const color = scaleQuantize({
    domain: [
      Math.min(...world.features.map((f) => f.properties.totalUsers)),
      Math.max(...world.features.map((f) => f.properties.totalUsers)),
    ],
    range: theme.palette.vis.worldMap
  });

  const handleResize = () => {
    setVisWidth(visContainerRef.current.clientWidth - 28);
    setVisHeight((visContainerRef.current.clientWidth - 28) / 1.5);
  };

  return (
    <Paper sx={{
            width: '100%',
            height: '100%',
            p: 4,
            position: 'relative',
            '&:hover .download-icon': {visibility: 'visible'}
          }}
           ref={visContainerRef}
    >
      {title && <Typography sx={{fontWeight: 'bold'}}>{title}</Typography>}
      <Box sx={{display: 'flex', mt: 4}}>
        <svg width={visWidth} height={visHeight}>
          <rect x={0} y={0} width={visWidth} height={visHeight} fill={background} rx={14}/>

          <Mercator
            data={world.features}
            scale={scale}
            translate={[centerX, centerY + 50]}
            >
            {(mercator) => (
              <g>
                <Graticule graticule={(g) => mercator.path(g) || ''} stroke="rgba(33,33,33,0.05)"/>
                {mercator.features.map(({feature, path}, i) => (
                  <path
                    key={`map-feature-${i}`}
                    d={path || ''}
                    fill={color(feature.properties.totalUsers)}
                    data-feature={JSON.stringify({
                      countryCode: feature.id,
                      countryName: feature.properties.name,
                      usersPercentage: feature.properties.usersPercentage,
                      usersCount: feature.properties.totalUsers
                    })}
                    stroke={background}
                    strokeWidth={0.5}
                    onMouseLeave={() => {
                      hideTooltip();
                    }}
                    onMouseMove={(event) => {
                      showTooltip({
                        tooltipData: calculateTooltipData(event),
                        tooltipTop: event.clientY,
                        tooltipLeft: event.clientX
                      });
                    }}
                  />
                ))}
              </g>
            )}
          </Mercator>
        </svg>
      </Box>

      {tooltipOpen && tooltipData && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <Typography variant="body2"><b>{tooltipData.countryName}</b> - {toHumanPercentage(tooltipData.usersPercentage)} ({tooltipData.usersCount} active users)</Typography>
        </TooltipInPortal>
      )}

      <ResizeListener onResize={handleResize} />
    </Paper>
  );
}


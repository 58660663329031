import React, {useState} from 'react';
import {NavLink as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Button, Collapse, ListItem, Typography, useTheme} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {THEMES} from "../../../../constants";

const NavBarItem = ({
                      children,
                      className,
                      depth,
                      href,
                      icon: Icon,
                      info: Info,
                      open: openProp,
                      title,
                      ...rest
                    }) => {
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const theme = useTheme();

  if (children) {
    return (
      <ListItem disableGutters key={title}{...rest} sx={{ display: 'block', py: 0 }}>
        <Button onClick={handleToggle} sx={{ color: 'text.secondary', p: 2, justifyContent: 'flex-start', width: 1 }}>
          {Icon && (<Icon sx={{ display: 'flex', alignItems: 'center', mr: 1 }} size="20"/>)}
          <Typography sx={{ textTransform: 'none' }}>
            {title}
          </Typography>
          {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
        </Button>
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem disableGutters key={title}{...rest} sx={{ display: 'flex', py: 0, mb: 1 }}>
      <Button component={RouterLink} exact to={href}
              sx={{
                p: 4,
                pr: 0,
                justifyContent: 'flex-start',
                width: 1,
                color: 'secondary.darker',
                '&:hover': theme.name === THEMES.LIGHT ? {} : {
                  color: theme.palette.text.black + ' !important',
                  bgcolor: 'primary.main'
                },
              }}
              activeStyle={{
                color: theme.palette.primary.main,
              }}>
        {Icon && (<Icon sx={{ display: 'flex', alignItems: 'center', mr: 2 }} size="20"/>)}
        <Typography sx={{ fontWeight: '500', textTransform: 'none', letterSpacing: 1.8 }} variant="magda">
          {title}
        </Typography>
        {Info && <Info/>}
      </Button>
    </ListItem>
  );
};

NavBarItem.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavBarItem.defaultProps = {
  open: false
};

export default NavBarItem;

import {Box, IconButton, Paper} from "@mui/material";
import SimpleTextVisSkeleton from "../../../../components/vis/SimpleTextVisSkeleton";
import React from "react";
import {CloudDownload} from "@mui/icons-material";
import {convertJSONtoCSV, downloadCSV} from "../../../../utils/csvDownload";
import Table from "../../../../components/vis/Table";
import _countries from "../filters/countries.json";

const VisTopGeos = ({ data, isLoading, isLoadingForALongTime, sx, filters }) => {
  const headings = [
    { key: "country", label: "Country" },
    { key: "app_installs", label: "App Installs", align: "right" },
  ];

  const COUNTRIES = _countries.map((c) => ({
    label: c.name,
    value: c["alpha-2"],
  }));

  const rows = data?.map(row => ({
    country: COUNTRIES.find(c => c.value === row.country_code).label,
    app_installs: `${row.app_installs}`,
  }));

  const hasData = data && data.length > 0;

  const download = () => {
    const downloadableData = {
      ...rows,
      ...filters,
      time: (new Date()).toDateString(),
    };
    const csvContent = convertJSONtoCSV(downloadableData);

    if (csvContent) {
      downloadCSV(downloadableData, 'Top Geos');
    }
  };

  return (
    <Box sx={{ display: "flex", ...sx }}>
      {isLoading &&
        <SimpleTextVisSkeleton sx={{ width:  "100%", bgcolor: "background.paper" }}
                               animation="wave"
                               title={isLoadingForALongTime ? "Top Geos - working" : "Top Geos"} />
      }

      {!isLoading && !hasData && (
        <SimpleTextVisSkeleton sx={{ width:  "100%", bgcolor: "background.paper" }}
                               animation={false}
                               title={"Top Geos - no data"} />
      )}

      {!isLoading && hasData && (
        <Paper sx={{ width: "100%", height: "100%", p: 4, position: 'relative', '&:hover .download-icon': { visibility: 'visible'} }}>
          <Table
            headings={headings}
            rows={rows}
            title="Top Geos"
            hideHeadings={false}
          />

          <IconButton aria-label="download" className="download-icon" sx={{ position: 'absolute', top: '-16px', right: '-16px', visibility: 'hidden' }}
                      onClick={download}>
            <CloudDownload />
          </IconButton>
        </Paper>
      )}
    </Box>
  );
}

export default VisTopGeos;
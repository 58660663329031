import _ from 'lodash';
import {colors, createTheme as createMuiTheme, responsiveFontSizes} from '@mui/material';
import {THEMES} from '../constants';
import {softShadows, strongShadows} from './shadows';
import typography from './typography';

export const proprietaryColors = {
  white: 'rgba(255, 255, 255, 1)',
  grayLightest: 'rgba(249, 249, 249, 1)',
  grayLighter: 'rgba(220, 221, 222, 1)',
  grayLight: 'rgba(214, 216, 218, 1)',
  gray: 'rgba(118, 125, 132, 1)',
  black: 'rgba(63, 65, 84, 1)',
  fullBlack: 'rgba(0, 0, 0, 1)',
  tappaBlue: 'rgba(10, 34, 255, 1)',
  tappaYellow: 'rgba(236, 255, 15, 1)',

  darkestBlue: 'rgba(51, 55, 72, 1)',
  blue: 'rgba(64, 150, 247, 1)',
  lighterBlue: 'rgba(0, 150, 255, 0.25)',
  lightestBlue: 'rgba(0, 150, 255, 0.075)',

  error: 'rgba(235, 4, 0, 1)',
  success: 'rgba(50, 218, 67, 1)',
  warning: 'rgba(242, 153, 48, 1)',
  info: 'rgba(59, 198, 241, 1)',

  visColor1: 'rgba(10, 34, 255, 1)',
  visColor1Third: 'rgba(10, 34, 255, 0.33)',
  visColor2: 'rgba(236, 255, 15, 1)',
  visColor2Third: 'rgba(252, 230, 1, 0.33)',
  visColor3: 'rgba(51, 55, 72, 1)',
  visColor3Third: 'rgba(51, 55, 72, 0.33)',
  visColor4: 'rgba(149, 159, 173, 1)',
  visColor4Third: 'rgba(149, 159, 173, 0.33)',
  visColor5: 'rgba(214, 255, 246, 1)',
  visColor5Third: 'rgba(214, 255, 246, 0.33)',
  visColor6: 'rgba(35, 22, 81, 1)',
  visColor6Third: 'rgba(35, 22, 81, 0.33)',
  visColor7: 'rgba(77, 204, 189, 1)',
  visColor7Third: 'rgba(77, 204, 189, 0.33)',
  visColor8: 'rgba(35, 116, 171, 1)',
  visColor8Third: 'rgba(35, 116, 171, 0.33)',
  visColor9: 'rgba(237, 62, 46, 1)',
  visColor9Third: 'rgba(237, 62, 46, 0.33)',
  visColor10: 'rgba(255, 134, 0, 1)',
  visColor10Third: 'rgba(255, 134, 0, 0.33)',
  visColor11: 'rgba(179, 0, 134, 1)',
  visColor11Third: 'rgba(179, 0, 134, 0.33)',
  visColor12: 'rgba(0, 150, 136, 1)',
  visColor12Third: 'rgba(0, 150, 136, 0.33)',
  visColor13: 'rgba(223, 210, 201, 1)',
  visColor13Third: 'rgba(223, 210, 201, 0.33)',
  visColor14: 'rgba(63, 65, 86, 1)',
  visColor15: 'rgba(125, 133, 155, 1)',
  visColor16: 'rgba(208, 209, 232, 1)',
  visColor16Third: 'rgba(208, 209, 232, 0.33)',

  visWorldHeatMapBlue: [
    "rgba(125, 133, 155, 1)",
    "rgba(112, 120, 159, 1)",
    "rgba(99, 108, 164, 1)",
    "rgba(86, 95, 169, 1)",
    "rgba(73, 82, 174, 1)",
    "rgba(60, 70, 179, 1)",
    "rgba(47, 57, 184, 1)",
    "rgba(34, 44, 198, 1)",
    "rgba(21, 40, 236, 1)",
    "rgba(10, 34, 255, 1)"
  ],

  visWorldHeatMapYellow: [
    "rgba(125, 133, 155, 1)",
    "rgba(142, 156, 135, 1)",
    "rgba(159, 178, 115, 1)",
    "rgba(175, 200, 95, 1)",
    "rgba(192, 223, 75, 1)",
    "rgba(199, 227, 66, 1)",
    "rgba(206, 232, 57, 1)",
    "rgba(213, 238, 47, 1)",
    "rgba(225, 247, 31, 1)",
    "rgba(236, 255, 15, 1)"
  ],
};

const baseOptions = {
  direction: 'ltr',
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1680,
    },
  },
  typography,
  spacing: (factor) => `${0.25 * factor}rem`,
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiLinearProgress: {
      root: {
        borderRadius: 5,
        height: '10px',
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: proprietaryColors.gray
        }
      }
    },
  }
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    components: {
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            textTransform: 'uppercase',
            a: {
              color: proprietaryColors.gray,
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: '0.8rem',
              letterSpacing: 1,

              ':hover': {
                color: proprietaryColors.black
              }
            }
          }
        }
      }
    },

    palette: {
      mode: 'light',
      action: {
        active: proprietaryColors.gray
      },
      background: {
        default: proprietaryColors.white,
        paper: proprietaryColors.white,
        dark: proprietaryColors.grayLightest,
        darkest: proprietaryColors.grayLighter,
        black: proprietaryColors.black,
      },
      primary: {
        main: proprietaryColors.tappaBlue,
        accent: proprietaryColors.tappaYellow,
      },
      secondary: {
        main: proprietaryColors.grayLighter,
        dark: proprietaryColors.grayLight,
        darker: proprietaryColors.gray,
      },
      tertiary: {
        main: proprietaryColors.black,
        light: proprietaryColors.grayLightest,
      },
      success: {
        main: proprietaryColors.success,
      },
      warning: {
        main: proprietaryColors.warning,
      },
      danger: {
        main: proprietaryColors.error,
      },
      text: {
        primary: proprietaryColors.black,
        primaryContrast: proprietaryColors.white,
        secondary: proprietaryColors.gray,
        tertiary: proprietaryColors.grayLighter,
        lightGray: proprietaryColors.grayLighter,
        white: proprietaryColors.white,
        black: proprietaryColors.black,
        fullBlack: proprietaryColors.fullBlack,
        yellow: proprietaryColors.yellow,
      },
      vis: {
        pie: {
          one: proprietaryColors.visColor1,
          two: proprietaryColors.visColor16,
          three: proprietaryColors.visColor2,
          four: proprietaryColors.visColor15,
          five: proprietaryColors.visColor14,
          six: proprietaryColors.visColor13,
        },
        histogram: {
          one: proprietaryColors.visColor1,
          two: proprietaryColors.visColor16,
        },
        counters: {
          deltaPositiveBackground: proprietaryColors.visColor1,
          deltaNegativeBackground: proprietaryColors.visColor16,
          deltaPositiveText: proprietaryColors.white,
          deltaNegativeText: proprietaryColors.black,
        },
        worldMap: proprietaryColors.visWorldHeatMapBlue,
        blendedVis: {
          leftAxis: {
            one: proprietaryColors.visColor1,
            two: proprietaryColors.visColor16,
            three: proprietaryColors.visColor3,
            four: proprietaryColors.visColor7,
            five: proprietaryColors.visColor6
          },
          leftAxisTransparent: {
            one: proprietaryColors.visColor1Third,
            two: proprietaryColors.visColor16Third,
            three: proprietaryColors.visColor3Third,
            four: proprietaryColors.visColor7Third,
            five: proprietaryColors.visColor6Third,
          },
          rightAxis: {
            one: proprietaryColors.visColor8,
            two: proprietaryColors.visColor9,
            three: proprietaryColors.visColor10,
            four: proprietaryColors.visColor11,
            five: proprietaryColors.visColor12,
          },
          rightAxisTransparent: {
            one: proprietaryColors.visColor8Third,
            two: proprietaryColors.visColor9Third,
            three: proprietaryColors.visColor10Third,
            four: proprietaryColors.visColor11Third,
            five: proprietaryColors.visColor12Third,
          },
        },
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.DARK,
    palette: {
      mode: 'dark',
      action: {
        active: proprietaryColors.gray
      },
      background: {
        default: proprietaryColors.black,
        paper: proprietaryColors.darkestBlue,
        dark: proprietaryColors.darkestBlue,
        darkest: proprietaryColors.gray,
        black: proprietaryColors.black,
      },
      primary: {
        main: proprietaryColors.tappaYellow,
        accent: proprietaryColors.grayLightest,
      },
      secondary: {
        main: proprietaryColors.grayLighter,
        dark: proprietaryColors.grayLight,
        darker: proprietaryColors.gray,
      },
      tertiary: {
        main: proprietaryColors.gray,
        light: proprietaryColors.grayLightest,
      },
      success: {
        main: proprietaryColors.success,
      },
      warning: {
        main: proprietaryColors.warning,
      },
      danger: {
        main: proprietaryColors.error,
      },
      text: {
        primary: proprietaryColors.white,
        primaryContrast: proprietaryColors.fullBlack,
        secondary: proprietaryColors.grayLight,
        tertiary: proprietaryColors.gray,
        lightGray: proprietaryColors.grayLighter,
        white: proprietaryColors.white,
        black: proprietaryColors.black,
        yellow: proprietaryColors.yellow,
      },
      vis: {
        pie: {
          one: proprietaryColors.white,
          two: proprietaryColors.visColor2,
          three: proprietaryColors.visColor13,
          four: proprietaryColors.visColor16,
          five: proprietaryColors.visColor1,
          six: proprietaryColors.visColor15,
        },
        histogram: {
          one: proprietaryColors.visColor2,
          two: proprietaryColors.visColor16,
        },
        counters: {
          deltaPositiveBackground: proprietaryColors.visColor2,
          deltaNegativeBackground: proprietaryColors.visColor16,
          deltaPositiveText: proprietaryColors.black,
          deltaNegativeText: proprietaryColors.black,
        },
        worldMap: proprietaryColors.visWorldHeatMapYellow,
        blendedVis: {
          leftAxis: {
            one: proprietaryColors.visColor2,
            two: proprietaryColors.visColor13,
            three: proprietaryColors.visColor3,
            four: proprietaryColors.visColor7,
            five: proprietaryColors.visColor6
          },
          leftAxisTransparent: {
            one: proprietaryColors.visColor2Third,
            two: proprietaryColors.visColor13Third,
            three: proprietaryColors.visColor3Third,
            four: proprietaryColors.visColor7Third,
            five: proprietaryColors.visColor6Third,
          },
          rightAxis: {
            one: proprietaryColors.visColor16,
            two: proprietaryColors.visColor9,
            three: proprietaryColors.visColor10,
            four: proprietaryColors.visColor11,
            five: proprietaryColors.visColor12,
          },
          rightAxisTransparent: {
            one: proprietaryColors.visColor8Third,
            two: proprietaryColors.visColor9Third,
            three: proprietaryColors.visColor10Third,
            four: proprietaryColors.visColor11Third,
            five: proprietaryColors.visColor12Third,
          },
        },
      }
    },
    shadows: strongShadows
  }
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseOptions,
      themeOptions,
      {direction: config.direction}
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}

import React, {useState, useEffect, useMemo} from "react";
import connect from "react-redux/es/connect/connect";
import {Box, MenuItem, Select, Typography} from "@mui/material";
import {capitalizeSnakeCase} from "@/utils/string";
import Bar from "@/components/vis/Bar";
import SimpleRectangleVisSkeleton from "../../../../components/vis/SimpleRectangleVisSkeleton";

const stateToProps = ({ insights: {sections: { linguistics } }}) => {
  return {
    isLoading: linguistics.isLoading,
    isLoadingForALongTime: linguistics.isLoadingForALongTime,
    data: linguistics.data,
  };
};

function mapData(data, model) {
  const filteredData = data && data[model] && Object.values(data[model]); // Get data for a certain model

  if (!filteredData) {
    return []; // Return an empty array if the specified model doesn't exist in the data
  }

  const sortOrder = {
    "1-10": 1,
    "10-20": 2,
    "20-30": 3,
    "30-40": 4,
    "40-50": 5,
    "50-60": 6,
    "60-70": 7,
    "70-80": 8,
    "80-90": 9,
    "90-100": 10
  };

  return filteredData
    .sort((a,b) => (sortOrder[a.category] > sortOrder[b.category]) ? 1 : ((sortOrder[b.category] > sortOrder[a.category]) ? -1 : 0))
    .map((d) => ({
      label: d.bucket,
      value: d.users_count,
    }));
}

const VisLinguistics = ({ sx={}, isLoading, isLoadingForALongTime, data, filters }) => {
  const modelOptions = [
                        "connection", 
                        "depth_of_feelings", 
                        "leadership", 
                        "moodiness", 
                        "personality_agreeableness", 
                        "personality_conscientiousness", 
                        "personality_extraversion", 
                        "personality_neuroticism", 
                        "personality_openness", 
                        "vocabulary_richness"]; // Manually assigned model names
  
  const [selectedModel, setSelectedModel] = useState(modelOptions[0]);

  const hasData = useMemo(
    () => {
      return data && data[selectedModel] && mapData(data, selectedModel).some(bucket => bucket.value !== 0);
    },
    [selectedModel, data]
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
      <Box sx={{ display: "flex", bgcolor: "background.paper", boxShadow: "0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 2px 0 rgba(63, 63, 68, 0.15)", borderRadius: 1 }}>
        <Box sx={{ width: "100%", height: "100%", p: 4 }}>
          {isLoading && (
            <SimpleRectangleVisSkeleton animation="wave" sx={{ width: "100%", height: "100%" }}
                                        title={isLoadingForALongTime ? "Linguistics models - working" : "Linguistics models"} />
          )}

          {!isLoading && !hasData && (
            <SimpleRectangleVisSkeleton animation={false} sx={{ width: "100%", height: "100%", bgcolor: "background.paper" }}
                                        title="Linguistics models - no data" />
          )}

          {!isLoading && hasData && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pb: 4 }}>
                <Typography sx={{ fontWeight: "bold", alignSelf: "flex-start" }}>Linguistics models</Typography>

                <Select
                  value={selectedModel}
                  onChange={(event) => {
                    setSelectedModel(event.target.value);
                  }}
                  variant="standard"
                  sx={{ width: 350, mx: 4, p: 2 }}
                >
                  {modelOptions.map((model) => (
                    <MenuItem key={model} value={model}>
                      {capitalizeSnakeCase(model).split('_').join(' ')}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Bar
                title={capitalizeSnakeCase(selectedModel).split('_').join(' ')}
                height="300"
                xLabel="Predicted %"
                yLabel="# Distinct Users"
                data={mapData(data, selectedModel)}
                filters={filters}
                sx={{ border: 0, boxShadow: 'none' }}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default connect(stateToProps)(VisLinguistics);



import connect from "react-redux/es/connect/connect";
import {
  Box,
  Button, CircularProgress, Dialog, DialogContent, FormControl, FormHelperText, Grid, InputLabel, MenuItem,
  Paper, Select, TextField, Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {useSnackbar} from "notistack";
import * as Yup from "yup";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from '@mui/material/LinearProgress';

const stateToProps = (state) => ({

});

const actionToProps = (dispatch) => ({

});

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} sx={{ borderRadius: 5, height: '7px' }} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const CustomInsightForm = ({ tenantId, dialogOpen, createCustomInsight, loadCreateCustomInsight, customInsightsTemplates, ...props}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [newCustomInsightFormShown, setNewCustomInsightFormShown] = useState(false);
  const [selectedCustomInsightTemplate, setSelectedCustomInsightTemplate] = useState(customInsightsTemplates.data[0]);

  useEffect(() => {
    if( !createCustomInsight.isLoading && createCustomInsight.success === true ) {
      setNewCustomInsightFormShown(false);

      createCustomInsightFormik.resetForm();

      enqueueSnackbar('Your new custom insight has been created', {
        variant: 'success',
        persist: true
      });
    }

    if( !createCustomInsight.isLoading && createCustomInsight.success === false ) {
      setNewCustomInsightFormShown(false);

      enqueueSnackbar('We\'ve encountered a problem creating your new custom insight, please try again later', {
        variant: 'error',
        persist: true
      });
    }
  }, [createCustomInsight]);

  useEffect(() => {

  }, [customInsightsTemplates]);

  const createCustomInsightFormik = useFormik({
    initialValues: {
      name: '',
      aggregationMethod: 'most_frequent_response',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Insight name is required.')
        .min(1, 'Insight name must be at least 1 characters long')
        .max(64, 'Insight name cannot be longer than 64 characters'),
      aggregationMethod: Yup.string().required('Aggregation method is required.')
        .oneOf(['most_frequent_response', 'any_yes', 'every_unique', 'top_{n}'], 'The selected aggregation method is wrong.'),
      topNumber: Yup.number('It must be a number.').when('aggregationMethod', {
        is: 'top_{n}',
        then: Yup.number().required('You must specify how many top results should be aggregated.'),
        otherwise: Yup.number().notRequired(),
      }),
      ...selectedCustomInsightTemplate.parameters.reduce((schema, parameter) => {
        schema[parameter.name] = Yup.string().required(`${snakeCaseToCapitalizedSentence(parameter.name)} is required`)
                                        .min(1, `${snakeCaseToCapitalizedSentence(parameter.name)} must be at least 3 characters long`)
                                        .max(128, `${snakeCaseToCapitalizedSentence(parameter.name)} cannot be longer than 128 characters`);
        return schema;
      }, {})
    }),
  });

  const handleDialogClose = () => {
    setNewCustomInsightFormShown(false);
  };

  const handleCreate = () => {
    createCustomInsightFormik.validateForm().then((errors) => {
      if( Object.keys(errors).length === 0 ) {
        loadCreateCustomInsight({
          tenantId: tenantId,
          payloadData: {
            template: selectedCustomInsightTemplate.name,
            aggregationMethod: selectedCustomInsightTemplate.aggregationMethod,
            topNumber: selectedCustomInsightTemplate.topNumber,
            ...createCustomInsightFormik.values
          },
        });
      }
    });
  };

  function snakeCaseToCapitalizedSentence(snakeCaseString) {
    const words = snakeCaseString.split('_');
    const firstWord = words[0];
    const capitalizedFirstWord = firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
    const restOfSentence = words.slice(1).join(' ');
    return capitalizedFirstWord + (restOfSentence ? ' ' + restOfSentence : '');
  }

  const handleAggregationMethodSelected = event => {
    createCustomInsightFormik.setFieldValue('aggregationMethod', event.target.value);
  }

  const handleCustomInsightTemplateSelected = event => {
    const template = customInsightsTemplates.data.find(template => template.name === event.target.value);

    setSelectedCustomInsightTemplate(template);
  }

  const calculateTemplate = () => {
    return selectedCustomInsightTemplate.template.replace(/\${(.*?)}/g, (match, key) => createCustomInsightFormik.values[key.trim()] || match);
  };

  const calculateTemplateProgress = () => {
    const filledFieldsTotal = selectedCustomInsightTemplate.parameters.length + (createCustomInsightFormik.values.aggregationMethod === 'top_{n}' ? 3 : 2);
    const filledFieldsCount = Object.entries(createCustomInsightFormik.values).filter(([key, value]) =>  !!value && key !== 'top_{n}').length
      + (!!createCustomInsightFormik.values.aggregationMethod !== 'top_{n}' ? 0 : (!!createCustomInsightFormik.values.topNumber ? 1 : 0));

    return (filledFieldsCount / filledFieldsTotal) * 100;
  };

  if( !newCustomInsightFormShown ) {
    return (
      <Button href="#" sx={{ my: 6 }} onClick={() => { setNewCustomInsightFormShown(true) }}>Create new custom Insight</Button>
    );
  } else {
    return (
      <Dialog open={newCustomInsightFormShown} aria-labelledby="custom-insight-add-modal" onClose={() => handleDialogClose()}>
        <DialogTitle id="audience-add-modal-title" sx={{ fontSize: '1.5rem' }}>
          Create a new custom insight
        </DialogTitle>

        <DialogContent>
          <form noValidate>
            <Grid container={true} spacing={4} sx={{ py: 6 }}>
              <Grid item xs={12}>
                <Typography variant="body2">What's the name of your new custom Insight?</Typography>

                <TextField error={Boolean(createCustomInsightFormik.touched.name && createCustomInsightFormik.errors.name)}
                           fullWidth
                           helperText={createCustomInsightFormik.touched.name && createCustomInsightFormik.errors.name}
                           label="Custom Insight name"
                           margin="normal"
                           name="name"
                           onBlur={createCustomInsightFormik.handleBlur}
                           onChange={createCustomInsightFormik.handleChange}
                           type="text"
                           value={createCustomInsightFormik.values.name}
                           variant="outlined" />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="custom-insights-template-label">Custom Insight template</InputLabel>

                  <Select
                    labelId="custom-insights-template-label"
                    id="custom-insights-template-select"
                    value={selectedCustomInsightTemplate.name}
                    label="Custom Insight template"
                    onChange={handleCustomInsightTemplateSelected}
                  >
                    {customInsightsTemplates.data.map(template => (
                      <MenuItem key={template.name} value={template.name}>{snakeCaseToCapitalizedSentence(template.name)}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {selectedCustomInsightTemplate && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">{selectedCustomInsightTemplate.description}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Paper elevation={0} sx={{ p: 4, bgcolor: 'primary.lightest' }}>
                      <Typography variant="body2"><i>{calculateTemplate()}</i></Typography>
                    </Paper>

                    <Box sx={{ mt: 4 }}>
                      <LinearProgressWithLabel value={calculateTemplateProgress()} />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ mb: 4 }}>Aggregation method to be used when cumulating the LLM interpretations.</Typography>

                    <FormControl fullWidth>
                      <InputLabel id="custom-insights-aggreagation-method-select">Method of Aggregation</InputLabel>

                      <Select error={Boolean(createCustomInsightFormik.touched.aggregationMethod && createCustomInsightFormik.errors.aggregationMethod)}
                              labelId="custom-insights-aggreagation-method-label"
                              id="custom-insights-aggreagation-method-select"
                              value={createCustomInsightFormik.values.aggregationMethod}
                              label="Method of Aggregation"
                              onChange={handleAggregationMethodSelected}
                            >
                        <MenuItem key="most_frequent_response" value="most_frequent_response">Most frequent response</MenuItem>
                        <MenuItem key="any_yes" value="any_yes">Any yes</MenuItem>
                        <MenuItem key="every_unique" value="every_unique">Every unique</MenuItem>
                        <MenuItem key="top_{n}" value="top_{n}">Top "n"</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {createCustomInsightFormik.values.aggregationMethod === 'top_{n}' && (
                    <Grid item xs={12}>
                      <Typography variant="body2">How many top results do you want to count when aggregating the data?</Typography>

                      <TextField error={Boolean(createCustomInsightFormik.touched.topNumber && createCustomInsightFormik.errors.topNumber)}
                                 helperText={createCustomInsightFormik.touched.topNumber && createCustomInsightFormik.errors.topNumber}
                                 fullWidth
                                 label="Top number"
                                 margin="normal"
                                 name="topNumber"
                                 onBlur={createCustomInsightFormik.handleBlur}
                                 onChange={createCustomInsightFormik.handleChange}
                                 type="text"
                                 value={createCustomInsightFormik.values.topNumber}
                                 variant="outlined" />
                    </Grid>
                  )}

                  {selectedCustomInsightTemplate.parameters.map(parameter => (
                    <Grid key={parameter.name} item xs={12}>
                      {(!parameter.rendition || parameter.rendition === 'textfield' || parameter.rendition === 'textarea') && (
                        <TextField error={Boolean(createCustomInsightFormik.touched[parameter.name] && createCustomInsightFormik.errors[parameter.name])}
                                   fullWidth
                                   helperText={
                                     parameter.description + '\n' +
                                     (createCustomInsightFormik.errors[parameter.name] ?? '')
                                   }
                                   multiline={parameter.rendition === 'textarea'}
                                   label={snakeCaseToCapitalizedSentence(parameter.name)}
                                   margin="normal"
                                   name={parameter.name}
                                   onBlur={createCustomInsightFormik.handleBlur}
                                   onChange={createCustomInsightFormik.handleChange}
                                   type="text"
                                   value={createCustomInsightFormik.values[parameter.name] || ''}
                                   variant="outlined" />
                      )}

                      {parameter.rendition === 'select' && (
                        <FormControl id={`select__form_control__${parameter.name}`} fullWidth>
                          <InputLabel id={`select_label__parameter__${parameter.name}`}>
                            {snakeCaseToCapitalizedSentence(parameter.name)}
                          </InputLabel>

                          <Select error={Boolean(createCustomInsightFormik.touched[parameter.name] && createCustomInsightFormik.errors[parameter.name])}
                                  labelId={`select_label__parameter__${parameter.name}`}
                                  id={`select__parameter__${parameter.name}`}
                                  name={parameter.name}
                                  label={snakeCaseToCapitalizedSentence(parameter.name)}
                                  onBlur={createCustomInsightFormik.handleBlur}
                                  onChange={createCustomInsightFormik.handleChange}
                                  value={createCustomInsightFormik.values[parameter.name] || ''}
                                  variant="outlined">
                            {parameter.values.map(possibleValue => (
                              <MenuItem key={`${parameter.name}__option__${possibleValue}`} value={possibleValue}>{possibleValue}</MenuItem>
                            ))}
                          </Select>

                          <FormHelperText error={Boolean(createCustomInsightFormik.touched[parameter.name] && createCustomInsightFormik.errors[parameter.name])}>
                            {
                              parameter.description + '\n' +
                              (createCustomInsightFormik.errors[parameter.name] ?? '')
                            }
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Grid>
                  ))}

                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleCreate}>
                      Create

                      {createCustomInsight.isLoading && (
                        <CircularProgress sx={{ ml: 2 }} size="20px" color="secondary" />
                      )}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
};

export default connect(stateToProps, actionToProps)(CustomInsightForm);
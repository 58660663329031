import React, { useMemo, useRef, useState} from "react";
import { Bar as VisBar } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import {AxisLeft, AxisTop} from "@visx/axis";
import { GridColumns } from "@visx/grid";
import {Box, IconButton, Paper, Typography, useTheme} from "@mui/material";
import { CloudDownload } from "@mui/icons-material";
import { convertJSONtoCSV, downloadCSV } from "../../utils/csvDownload";
import ResizeListener from "../../utils/ResizeListener";

const vMargin = 50; // Adjust margin
const hMargin = 160; // Adjust margin

export default function HorizontalBar({
                                        title,
                                        data,
                                        xLabel,
                                        yLabel,
                                        events = false,
                                        filters,
                                        color,
                                        handleBarClicked,
                                      }) {
  const theme = useTheme();
  const visContainerRef = useRef(null);
  const [visWidth, setVisWidth] = useState(450);

  const handleResize = () => {
    setVisWidth(visContainerRef.current.clientWidth - 24);
  };

  // Calculate height based on the number of labels
  const height = data.length * 15 + vMargin;

  // Bounds
  const xMax = visWidth - hMargin;
  const yMax = height - vMargin;

  // Scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleLinear({
        range: [0, xMax],
        round: true,
        domain: [0, Math.max(...data.map((d) => d.value))],
      }),
    [xMax]
  );

  const yScale = useMemo(
    () =>
      scaleBand({
        range: [0, yMax],
        round: true,
        domain: data.map((d) =>  d.label),
        paddingInner: 0.5,
        paddingOuter: 0,
      }),
    [data, yMax]
  );

  const download = () => {
    // Transform the data into the desired structure
    const transformedData = data.reduce((acc, x) => {
      acc[x.label] = x.value;
      return acc;
    }, {});

    // Combine the transformed data with metadata
    const downloadableData = {
      ...transformedData,
      ...filters,
      time: (new Date()).toDateString()
    };

    const csvContent = convertJSONtoCSV(downloadableData);

    if (csvContent) {
      downloadCSV(downloadableData, title);
    }
  };

  return visWidth < 10 ? null : (
    <Paper
      sx={{
        p: 4,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        "&:hover .download-icon": { visibility: "visible" },
      }}
    >
      {title && (
        <Typography sx={{ fontWeight: "bold", alignSelf: "flex-start" }}>
          {title}
        </Typography>
      )}

      <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', overflowY: 'auto' }}
           ref={visContainerRef} >
        <svg width={visWidth} height={height}>
          <Group top={vMargin} left={hMargin / 2}>
            <GridColumns
              scale={xScale}
              width={xMax}
              height={yMax}
              stroke={theme.palette.text.tertiary}
            />
            {data.map((d) => {
              const barWidth = xScale(d.value) ?? 0;
              const barHeight = yScale.bandwidth();
              const barX = 0;
              const barY = yScale(d.label);

              return (
                <VisBar
                  key={`bar-${d.label}`}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={color}
                  style={{
                    cursor: handleBarClicked ? 'pointer' : 'default',
                  }}
                  onClick={() => {
                    if( handleBarClicked ) {
                      handleBarClicked(d.label);
                    }
                  }}
                />
              );
            })}
          </Group>

          <AxisLeft
            scale={yScale}
            left={hMargin / 2}
            top={vMargin}
            labelProps={{ fill: theme.palette.text.primary }}
            tickLabelProps={{ fill: theme.palette.text.primary }}
            hideTicks={true}
            hideAxisLine={true}
            numTicks={data.length}
          />
          <AxisTop
            left={hMargin / 2}
            top={vMargin}
            labelProps={{ fill: theme.palette.text.primary }}
            tickLabelProps={{ fill: theme.palette.text.primary }}
            scale={xScale}
            hideTicks={true}
            hideAxisLine={true}
            label={xLabel}
          />
        </svg>
      </Box>

      <IconButton
        aria-label="download"
        className="download-icon"
        sx={{
          position: "absolute",
          top: "-16px",
          right: "-16px",
          visibility: "hidden",
        }}
        onClick={download}
      >
        <CloudDownload/>
      </IconButton>

      <ResizeListener onResize={handleResize} />
    </Paper>
  );
}
import {all, fork, put, select, takeEvery, race, join} from "redux-saga/effects";

import { apiGet } from "@/utils/api";
import {
  SET_LOADING,
  SET_DATA,
  SET_AUDIENCES,
  SET_AUDIENCE_MEMBERS,
  SET_CUSTOM_INSIGHTS,
  SET_CUSTOM_INSIGHTS_TEMPLATES, LOAD_AUDIENCES
} from "./types";
import { getFilters, getTenantId } from "./selectors";
import {Observable} from "rxjs";
import * as INSIGHTS from "./types";
import {apiDelete, apiPatch, apiPostJson} from "../../../utils/api";

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// TODO wrapping API here to fetch in saga - but this might be needed elsewhere
function get(url, data, options) {
  return apiGet(url, data, options)
    .mergeMap((res) => res.json())
    .toPromise()
    .then(function (response) {
      if (response && !response.errors) {
        return response;
      } else {
        return Promise.reject(response);
      }
    });
}

function clearEmpty(filters) {
  return Object.fromEntries(
    Object.entries(filters).filter(([k, v]) => {
      if (
        v === null ||
        v === undefined ||
        (Array.isArray(v) && v.length === 0)
      ) {
        return false;
      }

      return true;
    })
  );
}

function* fetchForType(type, url) {
  const filters = yield select(getFilters);
  const tenantId = yield select(getTenantId);

  if (tenantId === undefined) {
    return;
  }

  const apiUrl = `/${tenantId}/insights/${url}`;

  // Set isLoading to true for the given section immediately
  yield put({ type: SET_LOADING, payload: { section: type, isLoading: true, isLoadingForALongTime: false } });

  try {
    const task = yield fork(get, apiUrl, clearEmpty(filters));

    let { result } = yield race({
      result: join(task),
      timeout: delay(10000),
    });

    if (result) {
      // Request completed within 10 seconds, set isLoadingForALongTime to false
      yield put({ type: SET_LOADING, payload: { section: type, isLoading: true, isLoadingForALongTime: false } });
    } else {
      yield put({ type: SET_LOADING, payload: { section: type, isLoading: true, isLoadingForALongTime: true } });
      result = yield join(task);
    }

    // Data fetching successful, update the state
    yield put({ type: SET_DATA, payload: { section: type, data: result.data } });
  } catch (e) {
    console.error(e);
    // TODO handle errors in UI too
  } finally {
    // Set isLoading to false as soon as the request is done
    yield put({ type: SET_LOADING, payload: { section: type, isLoading: false } });
  }
}

function* fetchUser() {
  yield fetchForType("user", "dashboard/users");
}

function* fetchKeyboardEngagement() {
  yield fetchForType("keyboardEngagement", "dashboard/keyboard-engagement");
}

function* fetchRetention() {
  yield fetchForType("retention", "dashboard/retention");
}

function* fetchApps() {
  yield fetchForType("apps", "dashboard/apps");
}

function* fetchTypedTerms() {
  yield fetchForType("typedTerms", "dashboard/typed-terms");
}

function* fetchTrendingTypedTerms() {
  yield fetchForType("trendingTypedTerms", "dashboard/trending-typed-terms");
}

function* fetchSearchTerms() {
  yield fetchForType("searchTerms", "dashboard/search-terms");
}

function* fetchTrendingSearchTerms()  {
  yield fetchForType("trendingSearchTerms", "dashboard/trending-search-terms");
}

function* fetchGenders() {
  yield fetchForType("genders", "dashboard/genders");
}

function* fetchAgeGroups() {
  yield fetchForType("ageGroups", "dashboard/age-groups");
}

function* fetchInterests() {
  yield fetchForType("interests", "dashboard/interests");
}

function* fetchDevices() {
  yield fetchForType("devices", "dashboard/devices");
}

function* fetchKeyboardOpens() {
  yield fetchForType("keyboardOpens", "dashboard/keyboard-opens");
}

function* fetchRetentionLift() {
  yield fetchForType("retentionLift", "dashboard/retention-lift");
}

function* fetchLinguistics() {
  yield fetchForType("linguistics", "dashboard/linguistics");
}

function* fetchKbLanguages() {
  yield fetchForType("kbLanguages", "dashboard/kb-languages");
}

function* fetchCountries() {
  yield fetchForType("countries", "dashboard/countries");
}

function* fetchCustomInsightsResults() {
  yield fetchForType("customInsightsResults", "custom-insights/results");
}

function* fetchAdvancedDailyDashboard()  {
  yield fetchForType("advancedDailyDashboard", "daily-dashboard/advanced");
}

function* fetchSimpleDailyDashboard() {
  yield fetchForType("simpleDailyDashboard", "daily-dashboard/simple");
}

function* fetchRetentionDashboard() {
  yield fetchForType("retentionDashboard", "retention-dashboard/advanced");
}

function* onFiltersChanged(action) {
  const { dashboard } = action.payload;

  if( dashboard === 'old-dashboard' ) {
    yield all([
      fork(fetchUser),
      fork(fetchKeyboardEngagement),
      fork(fetchRetention),
      fork(fetchApps),
      fork(fetchTypedTerms),
      fork(fetchTrendingTypedTerms),
      fork(fetchSearchTerms),
      fork(fetchTrendingSearchTerms),
      fork(fetchDevices),
      fork(fetchKeyboardOpens),
      fork(fetchGenders),
      fork(fetchAgeGroups),
      fork(fetchInterests),
      fork(fetchLinguistics),
      fork(fetchKbLanguages),
      fork(fetchCountries),
      fork(fetchCustomInsightsResults),
    ]);
  }

  if( dashboard === 'simple-daily-dashboard' ) {
    yield all([
      fork(fetchSimpleDailyDashboard),
    ])
  }

  if( dashboard === 'advanced-daily-dashboard' ) {
    yield all([
      fork(fetchAdvancedDailyDashboard),
    ])
  }

  if( dashboard === 'advanced-retention-dashboard' ) {
    yield all([
      fork(fetchRetentionDashboard),
    ])
  }
}

function* onLoadAudiences(action) {
  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiGet(`/${action.payload.tenantId}/insights/audiences`)
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then((response) => {
      if (response && !response.errors) {
        data = response.data;
      } else {
        message = response.message;
      }
    });

  yield put({
    type: SET_AUDIENCES,
    payload: {data, message, statusCode}
  });
}

function* onLoadAudienceMembers(action) {
  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiGet(`/${action.payload.tenantId}/insights/audiences/${action.payload.audienceUid}/members`)
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then((response) => {
      if (response && !response.errors) {
        data = response.data;
      } else {
        message = response.message;
      }
    });

  yield put({
    type: SET_AUDIENCE_MEMBERS,
    payload: {
      audienceUid: action.payload.audienceUid,
      data,
      message,
      statusCode,
    },
  });
}

function* onLoadCreateAudience(action) {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let message = "";

  yield apiPostJson(`/${action.payload.tenantId}/insights/audiences`, {
    audience_name: action.payload.audienceName,
    third_party_ids_type: action.payload.thirdPartyIdType,
    third_party_ids: action.payload.userIds,
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      if ( !response || response.errors ) {
        message = response.message;
        errors = response.errors;
      }
    });

  yield put({
    type: INSIGHTS.SET_CREATE_AUDIENCE,
    payload: {errors, message, statusCode},
  });
}

function* onLoadUpdateAudience(action)  {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let data = {};
  let message = "";

  yield apiPatch(`/${action.payload.tenantId}/insights/audiences/${action.payload.audienceUid}`, {
    audience_name: action.payload.audienceName,
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      if ( !response || response.errors ) {
        message = response.message;
        errors = response.errors;
      } else {
        data = response.data;
      }
    });

  yield put({
    type: INSIGHTS.SET_UPDATE_AUDIENCE,
    payload: {
      audienceUid: action.payload.audienceUid,
      data,errors, message, statusCode},
  });
}

function* onLoadCheckAudiencesIntersection(action) {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let message = "";
  let data = {};

  yield apiPostJson(`/${action.payload.tenantId}/insights/audiences/intersection/check`, {
    audience_uids: action.payload.audienceUids,
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      if ( !response || response.errors ) {
        message = response.message;
        errors = response.errors;
      } else {
        data = response;
      }
    });

  yield put({
    type: INSIGHTS.SET_CHECK_AUDIENCES_INTERSECTION,
    payload: {data, errors, message, statusCode},
  });
}

function* onLoadIntersectAudiences(action) {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let message = "";
  let data = {};

  yield apiPostJson(`/${action.payload.tenantId}/insights/audiences/intersection`, {
    audience_name: action.payload.audienceName,
    audience_uids: action.payload.audienceUids,
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      if ( !response || response.errors ) {
        message = response.message;
        errors = response.errors;
      }
    });

  yield put({
    type: INSIGHTS.SET_INTERSECT_AUDIENCES,
    payload: {data, errors, message, statusCode},
  });
}

function* onLoadDeleteAudience(action) {
  const retry = 3;
  let statusCode = 0;
  let message = "";

  yield apiDelete(`/${action.payload.tenantId}/insights/audiences/${action.payload.audienceUid}`)
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then((response) => {
      message = response.message;
    });

  yield put({
    type: INSIGHTS.SET_DELETE_AUDIENCE,
    payload: {message, statusCode}
  });

  yield put({
    type: INSIGHTS.LOAD_AUDIENCES,
    payload: {
      tenantId: action.payload.tenantId,
    },
  });
}

function* onLoadCustomInsights(action) {
  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiGet(`/${action.payload.tenantId}/insights/custom-insights`)
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then((response) => {
      if (response && !response.errors) {
        data = response.data;
      } else {
        message = response.message;
      }
    });

  yield put({
    type: SET_CUSTOM_INSIGHTS,
    payload: {data, message, statusCode}
  });
}

function* onLoadCreateCustomInsight(action) {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let message = "";

  yield apiPostJson(`/${action.payload.tenantId}/insights/custom-insights`, {
    ...action.payload.payloadData
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      if ( !response || response.errors ) {
        message = response.message;
        errors = response.errors;
      }
    });

  yield put({
    type: INSIGHTS.SET_CREATE_CUSTOM_INSIGHT,
    payload: {errors, message, statusCode},
  });

  yield put({
    type: INSIGHTS.LOAD_CUSTOM_INSIGHTS,
    payload: {
      tenantId: action.payload.tenantId,
    },
  });
}

function* onLoadUpdateCustomInsight(action) {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let data = {};
  let message = "";

  yield apiPatch(`/${action.payload.tenantId}/insights/custom-insights/${action.payload.customInsightUid}`, {
    name: action.payload.customInsightName,
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      if ( !response || response.errors ) {
        message = response.message;
        errors = response.errors;
      } else {
        data = response.data;
      }
    });

  yield put({
    type: INSIGHTS.SET_UPDATE_CUSTOM_INSIGHT,
    payload: {
      customInsightUid: action.payload.customInsightUid,
      data, errors, message, statusCode
    },
  });
}

function* onLoadArchiveCustomInsight(action) {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let message = "";

  yield apiDelete(`/${action.payload.tenantId}/insights/custom-insights/${action.payload.customInsightUid}`)
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      message = response.message;

      if ( !response || response.errors ) {
        errors = response.errors;
      }
    });

  yield put({
    type: INSIGHTS.SET_ARCHIVE_CUSTOM_INSIGHT,
    payload: {errors, message, statusCode},
  });

  yield put({
    type: INSIGHTS.LOAD_CUSTOM_INSIGHTS,
    payload: {
      tenantId: action.payload.tenantId,
    },
  });
}

function* onLoadUnarchiveCustomInsight(action) {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let message = "";

  yield apiPostJson(`/${action.payload.tenantId}/insights/custom-insights/${action.payload.customInsightUid}/unarchive`, {})
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      message = response.message;

      if ( !response || response.errors ) {
        errors = response.errors;
      }
    });

  yield put({
    type: INSIGHTS.SET_UNARCHIVE_CUSTOM_INSIGHT,
    payload: {errors, message, statusCode},
  });

  yield put({
    type: INSIGHTS.LOAD_CUSTOM_INSIGHTS,
    payload: {
      tenantId: action.payload.tenantId,
    },
  });
}

function* onLoadCustomInsightsTemplates(action) {
  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiGet(`/insights/custom-insights-templates`)
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then((response) => {
      if (response && !response.errors) {
        data = response.data;
      } else {
        message = response.message;
      }
    });

  yield put({
    type: SET_CUSTOM_INSIGHTS_TEMPLATES,
    payload: {data, message, statusCode}
  });
}

function* watchInitialize() {
  yield takeEvery(INSIGHTS.FILTERS_CHANGED, onFiltersChanged);
  yield takeEvery(INSIGHTS.LOAD_INSIGHTS, onFiltersChanged);
  yield takeEvery(INSIGHTS.LOAD_AUDIENCES, onLoadAudiences);
  yield takeEvery(INSIGHTS.LOAD_AUDIENCE_MEMBERS, onLoadAudienceMembers);
  yield takeEvery(INSIGHTS.LOAD_CREATE_AUDIENCE, onLoadCreateAudience);
  yield takeEvery(INSIGHTS.LOAD_UPDATE_AUDIENCE, onLoadUpdateAudience);
  yield takeEvery(INSIGHTS.LOAD_CHECK_AUDIENCES_INTERSECTION, onLoadCheckAudiencesIntersection);
  yield takeEvery(INSIGHTS.LOAD_INTERSECT_AUDIENCES, onLoadIntersectAudiences);
  yield takeEvery(INSIGHTS.LOAD_DELETE_AUDIENCE, onLoadDeleteAudience);
  yield takeEvery(INSIGHTS.LOAD_CUSTOM_INSIGHTS, onLoadCustomInsights);
  yield takeEvery(INSIGHTS.LOAD_CREATE_CUSTOM_INSIGHT, onLoadCreateCustomInsight);
  yield takeEvery(INSIGHTS.LOAD_UPDATE_CUSTOM_INSIGHT, onLoadUpdateCustomInsight);
  yield takeEvery(INSIGHTS.LOAD_ARCHIVE_CUSTOM_INSIGHT, onLoadArchiveCustomInsight);
  yield takeEvery(INSIGHTS.LOAD_UNARCHIVE_CUSTOM_INSIGHT, onLoadUnarchiveCustomInsight);
  yield takeEvery(INSIGHTS.LOAD_CUSTOM_INSIGHTS_TEMPLATES, onLoadCustomInsightsTemplates);
}

export default function* sagas() {
  yield fork(watchInitialize);
}

import React from 'react';
import PropTypes from 'prop-types';
import {getValue} from "AppUtils/objects";

import clsx from 'clsx';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {useSnackbar} from 'notistack';
import {Box, Button, Card, CardContent, Grid, TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import LoadingScreen from "../../../components/LoadingScreen";

const useStyles = makeStyles(() => ({
  root: {}
}));

const UserAddForm = ({
                       className,
                       setIsSubmittingState,
                       isSubmittingState,
                       user,
                       errorsServer,
                       setErrorsServer,
                       ...props
                     }) => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  let roleList = [
    {'text': 'Content Manager', value: 'contentManager'},
    {'text': 'Admin', value: 'admin'}
  ];

  if (props.edit && !user) {
    return <LoadingScreen/>;
  }

  return (
    <Formik
      initialValues={{
        name: getValue(user, 'name', ''),
        email: getValue(user, 'email'),
        role: user.role ? {
          text: user.role.charAt(0).toUpperCase() + user.role.slice(1),
          value: user.role
        } : null,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).min(5, 'Must be at least 5 characters').required('Name is required'),
        email: Yup.string().max(255).email('Must be a valid email address').required(user ? false : 'Email is required'),
        role: Yup.string().max(255).required('Role is required').nullable(),
      })}
      onSubmit={(values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        setIsSubmittingState(true);
        if (props.edit) {
          props.updateUser(values);
        } else {
          props.addUser(values);
        }

        // resetForm();
        setSubmitting(false);
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
          setTouched,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
        >
          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name) || errorsServer.name}
                    fullWidth
                    helperText={(touched.name && errors.name) || errorsServer.name}
                    label="Full name"
                    name="name"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                      setErrorsServer({
                        ...errorsServer,
                        name: false
                      })
                    }}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.email && errors.email) || errorsServer.email}
                    fullWidth
                    helperText={(touched.email && errors.email) || errorsServer.email}
                    label="Email address"
                    name="email"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                      setErrorsServer({
                        ...errorsServer,
                        email: false
                      })
                    }}
                    required={user ? false : true}
                    value={values.email}
                    variant="outlined"
                    disabled={user ? true : false}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <Autocomplete
                    getOptionLabel={(option) => option.text}
                    options={roleList}
                    onBlur={() => setTouched({'role': true})}
                    onChange={(e, value) => {
                      setFieldValue("role", value || null)
                      setErrorsServer({
                        ...errorsServer,
                        role: false
                      })
                    }}
                    defaultValue={values.role}
                    includeInputInList
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.role && errors.role) || errorsServer.role}
                        fullWidth
                        helperText={(touched.role && errors.role) || errorsServer.role}
                        label="Role"
                        name="role"
                        variant="outlined"
                        value={values.role}
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmittingState}
                >
                  {props.edit ? 'Save changes' : 'New User'}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

UserAddForm.propTypes = {
  className: PropTypes.string,
  client: PropTypes.object.isRequired
};

export default UserAddForm;

import {Observable} from 'rxjs';
import {all, call, fork, put, putResolve, race, select, take, takeEvery, takeLatest} from 'redux-saga/effects';
import {eventChannel} from 'redux-saga';
import {LOCATION_CHANGE, push, replace} from 'connected-react-router';
import ls from 'local-storage';
import {createBrowserHistory} from 'history';

import {getValue} from 'AppUtils/objects';
import {adjustToLink} from 'AppUtils/url';
import logger from 'AppUtils/logging';

import authSagas from '../containers/auth/store/sagas';
import userSagas from '../containers/user/store/sagas';
import clientsSagas from '../containers/clients/store/sagas';
import contentKeyboardSagas from '../containers/keyboard-content/store/sagas';
import reportingSagas from '../containers/reporting/store/sagas';
import orchestrationSagas from '../containers/orechestration/store/sagas';
import insightsSagas from '../containers/insights/store/sagas';

import * as COMMON from './types';
import * as AUTH from '../containers/auth/store/types';
import * as USER from '../containers/user/store/types';
import * as CLIENTS from '../containers/clients/store/types';
import {appGetTexts} from "./selectors";
import {userChannels, userInfo} from "../containers/user/store/selectors";
import {loginAuthenticated, loginEmailVerified} from "../containers/auth/store/selectors";
import {UI_REDIRECT} from "./types";
import {getLoginAuthenticated, getLoginEmailVerified, getTwoFactorSet} from "../utils/localStorage";

/**
 * This is usually called once
 */
function* onInitialize() {
  // Attempt to obtain XSRF token and do the login check afterwards
  yield put({type: AUTH.LOAD_LOGIN_OBTAIN_XSRF_TOKEN});
  yield takeLatest(AUTH.LOAD_LOGIN_OBTAIN_XSRF_TOKEN, checkUserLogin);


  // Wait for the auth_check to issue set or reset
  const {set, reset} = yield race({
    set: take(AUTH.SET_LOGIN),
    reset: take(AUTH.RESET_LOGIN),
  });

  // Before considering the application ready
  const ready = yield preReady();

  if (ready) {
    // Mark the app as ready
    yield put({type: COMMON.APP_STATUS, payload: 'ready'});

    // Launch data refreshers
    yield fork(dataRefresher);
  } else {
    yield put({type: COMMON.APP_STATUS, payload: 'error'});
  }

  yield fork(postReady);
}

function* checkUserLogin() {
  // fork the auth checker
  yield fork(function* () {
    yield put({type: AUTH.LOAD_LOGIN_CHECK});
  });
}


function* dataRefresher() {
  yield [];
}

function getLang() {
  return ls.get('lang') || process.env.REACT_APP_DEFAULT_LANGUAGE;
}

function setLang(lang) {
  ls.set('lang', lang);
}

function* appLoadTranslations(action) {
  // get interface lang
  const lang = getValue(action, 'payload.lang', getLang());
  const appTexts = yield select(appGetTexts);

  setLang(lang);

  yield putResolve({
    type: COMMON.APP_SET_TRANSLATIONS,
    payload: {
      interfaceLang: lang,
      __store: appTexts && appTexts[lang] ? appTexts[lang] : '',
    },
  });
}

function* preReady() {
  const authenticated = yield select(loginAuthenticated);
  const verified = yield select(loginEmailVerified);
  const url = new URL(window.location);
  const path = url.pathname;
  const verifyingEmail = path === '/verify-email';

  if (authenticated && verified) {
    yield take(USER.USER_SET_INFO);
    const info = yield select(userInfo);
    const roles = getValue(info, 'roles', []);
    const organizations = getValue(info, 'organizations', []);
    const organizationRoles = organizations.map(item => item.userRole);
    const res = path.match(/\/clients\/([0-9]*)\//);
    const clientId = res && res[1] ? parseInt(res[1]) : '';
    const shouldBeRedirected = ['/', '/login', '/orchestration'].includes(path);

    if (clientId) {
      yield put({type: USER.USER_LOAD_CHANNELS});
      yield take(USER.USER_SET_CHANNELS);
      yield put({type: CLIENTS.LOAD_ORGANIZATION, payload: {id: clientId}});
    } else {
      yield put({type: CLIENTS.LOAD_ORGANIZATIONS});
    }

    if( shouldBeRedirected && organizations.length > 0 ) {
      if( organizationRoles.includes('admin') ) {
        yield put({type: UI_REDIRECT, payload: `${url.origin}/clients`});
      } else if ( organizationRoles.includes('contentManager') ) {
        yield put({type: UI_REDIRECT, payload: `${url.origin}/clients/${organizations[0].id}/keyboard-content`});
      }

      if( roles.includes('superadmin') ) {
        yield put({type: UI_REDIRECT, payload: `${url.origin}/clients`});
      }
    }

    if( path === '/clients' && organizations.length > 0 ) {
      if( !organizationRoles.includes('admin') && !roles.includes('superadmin') ) {
        yield put({type: UI_REDIRECT, payload: `${url.origin}/clients/${organizations[0].id}/keyboard-content`});
      }
    }

    if( organizations.length === 0 ) {
      yield put({type: UI_REDIRECT, payload: `${url.origin}/orchestration`});
    }


    return true;
  }
  else if (authenticated && !verified && !verifyingEmail) {
    yield put({type: UI_REDIRECT, payload: `${url.origin}/email-verification`});
    return true;
  }
  else {
    return true;
  }
}

function* postReady() {
  // Things to do after app is ready
}

function* appLoadSetStatusCode(payload) {
  yield put({type: COMMON.APP_STATUS, payload: payload.status});
}

function* appWatchInitialize() {
  yield takeLatest(COMMON.APP_INITIALIZE, onInitialize);
  yield takeLatest(COMMON.APP_LOAD_TRANSLATIONS, appLoadTranslations);
  yield takeLatest(COMMON.APP_LOAD_SET_STATUS_CODE, appLoadSetStatusCode);

  yield takeEvery('*', function* (action) {
    const state = yield select();

    if (getValue(action, 'payload.statusCode') == 500 && getValue(state, 'app.statusCode') != 500) {
      yield put({type: COMMON.APP_STATUS, payload: 'error'});
    }

    if (getValue(action, 'payload.statusCode') && getValue(state, 'app.statusCode') != getValue(action, 'payload.statusCode')) {
      yield put({type: COMMON.APP_SET_STATUS_CODE, payload: {statusCode: action.payload.statusCode}});
    }

    if (getValue(action, 'payload.info.notifications') || getValue(action, 'payload.notifications')) {
      yield put({type: USER.USER_LOAD_NOTIFICATIONS});
    }
  })
}

const appSagas = function* () {
  yield fork(appWatchInitialize);
};

/** END APP * */

/** START UI * */
export function* onLocationChange(action) {
  const section = 'main';
  const uchannels = yield select(userChannels);
  const url = new URL(window.location);
  const str = url.pathname;
  const res = str.match(/\/clients\/([0-9]*)\//);
  const clientId = res && res[1] ? parseInt(res[1]) : '';
  const twoFactorSet = getTwoFactorSet();
  const loginAuthenticated = getLoginAuthenticated();
  const loginEmailVerified = getLoginEmailVerified();
  const {payload} = action;
  const state = yield select();

  // Redirect the user to email verification screen if authenticated and two-factor auth is not set and email is not verified
  if( loginAuthenticated && !twoFactorSet && !loginEmailVerified && !['/email-verification', '/verify-email'].includes(payload.location.pathname) ) {
    yield put({type: UI_REDIRECT, payload: `${url.origin}/email-verification`});
  }

  // Redirect the user to two factor screen if authenticated and email is verified and two factor is not set
  if( loginAuthenticated && loginEmailVerified && !twoFactorSet && !['/two-factor', '/login'].includes(payload.location.pathname) ) {
    yield put({type: UI_REDIRECT, payload: `${url.origin}/two-factor`});
  }

  // Redirect away from the two factor view if the user has already set it up
  if( loginAuthenticated && loginEmailVerified && twoFactorSet && payload.location.pathname === '/two-factor' ) {
    yield put({type: UI_REDIRECT, payload: `${url.origin}`});
  }

  // Redirect away from the email verification view if the user has already verified his email
  if( loginAuthenticated && loginEmailVerified && ['/email-verification', '/verify-email'].includes(payload.location.pathname) ) {
    yield put({type: UI_REDIRECT, payload: `${url.origin}`});
  }

  // Redirect away from the registration view if the user is already logged in
  if( loginAuthenticated && payload.location.pathname === '/register' ) {
    yield put({type: UI_REDIRECT, payload: `${url.origin}`});
  }

  // Load user's clients if they aren't loaded yet
  if(clientId && (!uchannels.data || uchannels.data.length === 0)) {
    yield put({type: USER.USER_LOAD_CHANNELS});
    yield take(USER.USER_SET_CHANNELS);
  }

  // Reload the user's organizations information if navigating to pages where all organizations information is needed
  if( !getValue(state, 'clients.organizations.data') && [
    '/clients',
    '/reporting'
  ].includes(payload.location.pathname) ) {
    yield put({type: CLIENTS.LOAD_RESET_ORGANIZATION});
    yield put({type: CLIENTS.LOAD_ORGANIZATIONS});
  }

  // Reload the user's organization information if navigating to a client-specific page and the needed info is not there
  if( (!getValue(state, 'clients.organization.data') ||  getValue(state, 'clients.organization.data').id !== clientId) && [
    `/clients/${clientId}/keyboard-content`,
    `/clients/${clientId}/daily-dashboard`,
    `/clients/${clientId}/content-usage`,
    `/clients/${clientId}/insights`,
    `/clients/${clientId}/settings`,
    `/clients/${clientId}/reporting`,
  ].includes(payload.location.pathname) ) {
    yield put({type: CLIENTS.LOAD_RESET_ORGANIZATION});
    yield put({type: CLIENTS.LOAD_ORGANIZATION, payload: {id: clientId}});
  }

  if (payload) {
    const {state} = payload;
    // redirect to 404 if error404: true, before any initial location
    if (getValue(state, 'error404')) {
      yield put(push('/error/404'));
    }
  }


  yield put({
    type: COMMON.UI_URL,
    payload: {
      pathname: window.location.pathname,
      search: window.location.search,
      hash: window.location.hash,
    },
  });

  yield setInitialLocation(action.payload.pathname);
}

export function* setInitialLocation(pathname = '') {
  let section = '';
  let subsection = '';

  if (!pathname) {
    pathname = window.location.pathname;
  }

  if (pathname.indexOf('/') == 0 || pathname.indexOf('/') == 3) section = 'index';

  if (pathname.indexOf('/auth') == 0 || pathname.indexOf('/auth') == 3) section = 'auth';
  else if (pathname.indexOf('/login') == 0 || pathname.indexOf('/login') == 3) section = 'login';
  else if (pathname.indexOf('/register') == 0 || pathname.indexOf('/register') == 3) section = 'register';
  else if (pathname.indexOf('/signup') == 0 || pathname.indexOf('/signup') == 3) section = 'signup';
  else if (pathname.indexOf('/about') == 0 || pathname.indexOf('/about') == 3) section = 'about';
  else if (pathname.indexOf('/tos') == 0 || pathname.indexOf('/tos') == 3) section = 'tos';
  else if (pathname.indexOf('/faq') == 0 || pathname.indexOf('/faq') == 3) section = 'faq';

  if (pathname) {
    const subsectionArr = pathname.split('/');
    if (Array.isArray(subsectionArr)) {
      subsection = subsectionArr.pop();
    }

    if (subsectionArr.length <= 2 && section === subsection) {
      subsection = 'home';
    }
  }

  // check for URL params and if true check for saved paths
  if (!window.location.search) {
    const filtersState = ls.get('filtersState');
    const redirectFlag = ls.get('redirectFlag');
    const keyState = `${section}\\${subsection}`;

    // Uh check for local storage and then redirect
    if (filtersState && filtersState[keyState] && !redirectFlag) {
      const redirectTo = `${window.location.pathname}${filtersState[keyState]}`;
      logger.info(`gently redirected to: ${redirectTo}`);

      const history = createBrowserHistory();

      yield put(push({
        pathname: redirectTo,
        state: getValue(history, 'location.state', null)
      }));
      ls.set('redirectFlag', 1);
    } else {
      ls.set('redirectFlag', 0);
    }
  }

  yield put({type: COMMON.UI_SECTION_CHANGE, payload: {section, subsection}});
}

export function* onRedirect(action) {
  const to = adjustToLink(action.payload);

  yield put(push(to));
}

export function* onReplace(action) {
  const to = adjustToLink(action.payload);
  yield put(replace(to));
}

function* monitorResolution() {
  let defaultSize = '';

  function watcher() {
    return eventChannel(emitter => {
      const subscr$ = Observable.create(observer => {

        const callback = (e, size) => {
          if (e.matches && size) {
            defaultSize = size;
            observer.next(size);
          }
        };

        const xs = window.matchMedia('(max-width: 575px)');
        callback(xs, 'xs');
        xs.addListener((e) => callback(e, 'xs'));

        const sm = window.matchMedia('(min-width: 576px) and (max-width: 767px)');
        callback(sm, 'sm');
        sm.addListener((e) => callback(e, 'sm'));

        const md = window.matchMedia('(min-width: 768px) and (max-width: 991px)');
        callback(md, 'md');
        md.addListener((e) => callback(e, 'md'));

        const lg = window.matchMedia('(min-width: 992px) and (max-width: 1199px)');
        callback(lg, 'lg');
        lg.addListener((e) => callback(e, 'lg'));

        const xl = window.matchMedia('(min-width: 1200px)');
        callback(xl, 'xl');
        xl.addListener((e) => callback(e, 'xl'));
      })
        .map(computeScreenInfo)
        .catch(e => {
          return Observable.of(computeScreenInfo());
        })
        .subscribe(emitter);
      return () => {
        subscr$.unsubscribe();
      };
    });
  }

  const chan = yield call(watcher);
  try {
    let info = computeScreenInfo(defaultSize);
    yield put({type: COMMON.UI_DEVICE_INFO, payload: info});
    while (true) {
      info = yield take(chan);
      yield put({type: COMMON.UI_DEVICE_INFO, payload: info});
    }
  } finally {
  }
}

function computeScreenInfo(size) {
  return {
    size,
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    isMobile: size === 'sm' || size === 'xs',
  };
}

function* uiWatchInitialize() {
  yield fork(monitorResolution);
  yield takeEvery(LOCATION_CHANGE, onLocationChange);
  yield takeLatest(COMMON.UI_REDIRECT, onRedirect);
  yield takeLatest(COMMON.UI_REPLACE, onReplace);
}

const uiSagas = function* () {
  yield fork(uiWatchInitialize);
};
/** END UI * */


export default function* root() {
  try {
    yield all([
      fork(appSagas),
      fork(uiSagas),
      fork(authSagas),
      fork(userSagas),
      fork(clientsSagas),
      fork(contentKeyboardSagas),
      fork(reportingSagas),
      fork(orchestrationSagas),
      fork(insightsSagas),
    ]);
  } catch (e) {
    logger.error('Sagas ERROR');
    logger.error(e);
  }
}

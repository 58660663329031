import Pie from "@visx/shape/lib/shapes/Pie";
import { Group } from "@visx/group";
import { scaleOrdinal } from "@visx/scale";
import { animated, useTransition, interpolate } from "@react-spring/web";
import {Box, Typography, Tooltip, Paper, IconButton, useTheme} from "@mui/material";
import {CloudDownload} from "@mui/icons-material";
import {convertJSONtoCSV, downloadCSV} from "../../utils/csvDownload";
import {toHumanPercentage} from "../../utils/numbers";
import React, {useRef, useState} from "react";
import ResizeListener from "../../utils/ResizeListener";

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

export default function Donut({
  title,
  data,
  margin = defaultMargin,
  thickness = 20,
  filters
}) {
  const theme = useTheme();
  const visContainerRef = useRef(null);
  const [visSide, setVisSize] = useState(300);

  const handleResize = () => {
    setVisSize(Math.min(visContainerRef.current.clientWidth, visContainerRef.current.clientHeight, 335));
  };

  if (visSide < 10) return null;

  const innerWidth = visSide - margin.left - margin.right;
  const innerHeight = visSide - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const colors = Object.values(theme.palette.vis.pie);

  const getColor = scaleOrdinal({
    domain: data.map((d) => d.label),
    range: colors,
  });

  const download = () => {
    // Transform the data into the desired structure
    const transformedData = data.reduce((acc, x) => {
      acc[x.label] = toHumanPercentage(x.value);
      return acc;
    }, {});

    // Combine the transformed data with metadata
    const downloadableData = {
      ...transformedData,
      ...filters,
      time: (new Date()).toDateString()
    };

    const csvContent = convertJSONtoCSV(downloadableData);

    if (csvContent) {
      downloadCSV(downloadableData, title);
    }
  };

  return (
    <Paper sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", p: 4, position: 'relative', '&:hover .download-icon': { visibility: 'visible' } }}>
      {title && <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>}

      <Box sx={{ display: "flex", flexDirection: "column", mt: 4, flex: "1 1 0%" }}>
        <Box sx={{ display: "flex" }}>
          {data.filter(d => d.label).map((d) => (
            <Box key={d.label} sx={{ display: "flex", alignItems: "center", mr: 2 }}>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  marginRight: 2,
                  borderRadius: "50%",
                  backgroundColor: getColor(d.label),
                }}
              />{" "}
              <Tooltip title={d.value}>
                <span>
                  <Typography>{d.label}</Typography>
                </span>
              </Tooltip>
            </Box>
          ))}
        </Box>

        <Box sx={{ alignSelf: "center", flex: "1 1 0%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} ref={visContainerRef}>
          <svg width={visSide} height={visSide}>
            <Group top={centerY + margin.top} left={centerX + margin.left}>
              <Pie
                data={data}
                pieValue={(d) => d.value}
                outerRadius={radius}
                innerRadius={radius - thickness}
                cornerRadius={3}
                padAngle={0.005}
                pieSortValues={() => 0}
              >
                {(pie) => (
                  <AnimatedPie
                    {...pie}
                    animate={true}
                    getKey={(arc) => arc.data.label}
                    getColor={(arc) => getColor(arc.data.label)}
                  />
                )}
              </Pie>
            </Group>
          </svg>
        </Box>
      </Box>

      <IconButton aria-label="download" className="download-icon" sx={{ position: 'absolute', top: '-16px', right: '-16px', visibility: 'hidden' }}
                  onClick={download}>
        <CloudDownload />
      </IconButton>

      <ResizeListener onResize={handleResize} />
    </Paper>
  );
}

const fromLeaveTransition = ({ endAngle }) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});

const enterUpdateTransition = ({ startAngle, endAngle }) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

function AnimatedPie({ animate, arcs, path, getKey, getColor, onClickDatum }) {
  const transitions = useTransition(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });
  return transitions((props, arc, { key }) => {
    // const [centroidX, centroidY] = path.centroid(arc);
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;

    return (
      <g key={key}>
        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={interpolate(
            [props.startAngle, props.endAngle],
            (startAngle, endAngle) =>
              path({
                ...arc,
                startAngle,
                endAngle,
              })
          )}
          fill={getColor(arc)}
        />
        {hasSpaceForLabel && <animated.g style={{ opacity: props.opacity }} />}
      </g>
    );
  });
}

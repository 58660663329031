import {
  Box,
  List,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  ListItemIcon,
  CircularProgress,
  Tabs,
  Tab,
  FormLabel, FormGroup, Checkbox, Paper, Tooltip
} from "@mui/material";
import { styled } from '@mui/material/styles';
import connect from "react-redux/es/connect/connect";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {CloudUpload, Person} from "@mui/icons-material";
import {
  loadCheckAudiencesIntersection,
  loadCreateAudience,
  loadIntersectAudiences,
  resetCheckAudiencesIntersection
} from "../../store/actions";
import {getCheckAudiencesIntersection, getCreateAudience, getIntersectAudiences} from "../../store/selectors";
import TabPanel from "../../../../components/TabPanel";

const stateToProps = (state) => ({
  createAudience: getCreateAudience(state),
  intersectAudience: getIntersectAudiences(state),
  checkAudiencesIntersection: getCheckAudiencesIntersection(state),
});

const actionToProps = (dispatch) => ({
  loadCreateAudience: payload => dispatch(loadCreateAudience(payload)),
  loadCheckAudiencesIntersection: payload => dispatch(loadCheckAudiencesIntersection(payload)),
  resetCheckAudiencesIntersection: payload => dispatch(resetCheckAudiencesIntersection(payload)),
  loadIntersectAudiences: payload => dispatch(loadIntersectAudiences(payload)),
});

const AudienceAdd = ({ open, onClose, tenantId, loadAudiences, audiences, ...props }) => {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if( !props.createAudience.isLoading && props.createAudience.success === true ) {
      loadAudiences();
      handleClose();
    }

    if( !props.createAudience.isLoading && props.createAudience.success === false ) {
      audienceAddFormik.setFieldError('audienceName', props.createAudience.errors.audience_name);
      audienceAddFormik.setFieldError('thirdPartyIdType', props.createAudience.errors.third_party_ids_type);
      audienceAddFormik.setFieldError('userIds', props.createAudience.errors.third_party_ids);
    }
  }, [props.createAudience]);

  useEffect(() => {
    if( !props.intersectAudience.isLoading && props.intersectAudience.success === true ) {
      loadAudiences();
      handleClose();
    }
  }, [props.intersectAudience]);

  const handleClose = () => {
    if( !props.createAudience.isLoading ) {
      onClose();
      audienceAddFormik.resetForm();
      audienceIntersectionFormik.resetForm();
      props.resetCheckAudiencesIntersection();
    }
  };

  const handleSave = () => {
    if( props.createAudience.isLoading ) {
      return;
    }

    audienceAddFormik.validateForm().then((errors) => {
      if( Object.keys(errors).length !== 0 ) {
        return;
      }
      props.loadCreateAudience({
        tenantId: tenantId,
        ...audienceAddFormik.values,
      });
    });
  };

  const handleTabChange = (e, value) => {
    setTabIndex(value);
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const parseCSVFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const fileContent = event.target.result;

        if (fileContent) {
          // Split the content by newlines to create an array of user IDs
          const parsedUserIds = fileContent.trim().split('\n');
          resolve(parsedUserIds);
        } else {
          resolve(null); // Parsing failed
        }
      };

      reader.readAsText(file);
    });
  };

  const handleCsvChange = async (event) => {
    const file = event.target.files[0];
    await audienceAddFormik.setFieldValue('userIds', []);

    if (file) {
      // Check if the file has the correct MIME type for CSV
      if (file.type === 'text/csv') {
        const parsedUserIds = await parseCSVFile(file);

        if (parsedUserIds !== null) {
          const cleanedUserIds = parsedUserIds.map((userId) => userId.trim().replace(/,$/, ''));
          await audienceAddFormik.setFieldValue('userIds', cleanedUserIds);
          audienceAddFormik.setFieldError('userIds', '');
        } else {
          audienceAddFormik.setFieldError('userIds', 'CSV parsing failed');
          await audienceAddFormik.setFieldValue('userIds', []);
        }
      } else {
        audienceAddFormik.setFieldError('userIds', 'CSV parsing failed. Make sure it only contains the userIds on separate lines, with commas.');
      }
    }
  };

  const handleCheckIntersection = () => {
    if( props.loadCheckAudiencesIntersection.isLoading || props.loadIntersectAudiences.isLoading ) {
      return;
    }

    audienceIntersectionFormik.setTouched({
      audienceName: true,
      audienceUids: true,
    });

    audienceIntersectionFormik.validateForm().then((errors) => {
      if( Object.keys(errors).length !== 0 ) {
        return;
      }

      props.loadCheckAudiencesIntersection({
        tenantId: tenantId,
        ...audienceIntersectionFormik.values,
      });
    });
  };

  const handleCreateAudienceIntersection = () => {
    if( props.loadCheckAudiencesIntersection.isLoading || props.loadIntersectAudiences.isLoading ) {
      return;
    }

    audienceIntersectionFormik.setTouched({
      audienceName: true,
      audienceUids: true,
    });

    audienceIntersectionFormik.validateForm().then((errors) => {
      if( Object.keys(errors).length !== 0 ) {
        return;
      }

      props.loadIntersectAudiences({
        tenantId: tenantId,
        ...audienceIntersectionFormik.values,
      });
    });
  };

  const handleAudienceSelectionChanged = audience => {
    const { audienceUids } = audienceIntersectionFormik.values;
    const updatedAudienceUids = audienceUids.includes(audience.uid)
      ? audienceUids.filter(a => a !== audience.uid)
      : [...audienceUids, audience.uid];

    // Update Formik state using setFieldValue
    audienceIntersectionFormik.setFieldValue('audienceUids', updatedAudienceUids);

    // Mark the field as touched
    audienceIntersectionFormik.setFieldTouched('audienceUids', true, false);

    props.resetCheckAudiencesIntersection();
  };

  const canSelect = audience => {
    if( audienceIntersectionFormik.values.audienceUids.length === 0 ) {
      return true;
    }

    const firstAudience = audiences.data.find(a => a.uid === audienceIntersectionFormik.values.audienceUids[0]);

    return firstAudience.third_party_id_type === audience.third_party_id_type;
  }

  const audienceAddFormik = useFormik({
    initialValues: {
      audienceName: '',
      thirdPartyIdType: '',
      userIds: [],
    },
    validationSchema: Yup.object().shape({
      audienceName: Yup.string().required('Audience name is required.').max(128, 'Audience name cannot be larger than 128 characters.'),
      thirdPartyIdType: Yup.string().test(
        'correct-options-are-selected',
        'You must select at least one ID type',
        value => {
          return value === 'keyboard_user_id' || value === 'player_id';
        }
      ),
      userIds: Yup.array().required('You must upload a CSV with user Ids.')
    }),
  });

  const audienceIntersectionFormik = useFormik({
    initialValues: {
      audienceName: '',
      audienceUids: [],
    },
    validationSchema: Yup.object().shape({
      audienceName: Yup.string().required('Audience name is required.').max(128, 'Audience name cannot be larger than 128 characters.'),
      audienceUids: Yup.array().min(2, 'You must select at least 2 audiences'),
    }),
  });

  return (
    <Dialog open={open} aria-labelledby="audience-add-modal" onClose={() => handleClose()}>
      <DialogTitle id="audience-add-modal-title" sx={{ fontSize: '1.5rem' }}>
        Create a new audience
      </DialogTitle>

      <DialogContent>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab id="tab_button_csv_upload" key="tab_button_csv_upload" index={0} label="CSV Upload" />
          <Tab id="tab_button_intersection" key="tab_button_intersection" index={1} label="Audience Intersection" />
        </Tabs>

        <TabPanel value={tabIndex} index={0} name="CSV Upload" key="tab_panel_csv_upload">
          <DialogContentText id="audience-add-modal-text" sx={{ mt: 4 }}>
            In order to create an audience you must upload a CSV file containing just the IDs of your users. These can either be the keyboard user IDs, or the user IDs from your own app.
          </DialogContentText>

          <form noValidate onSubmit={audienceAddFormik.handleSubmit}>
            <Grid container={true} spacing={6} sx={{ py: 6 }}>
              <Grid item xs={12}>
                <Typography variant="body2">What's the name of your new audience?</Typography>

                <TextField error={Boolean(audienceAddFormik.touched.audienceName && audienceAddFormik.errors.audienceName)}
                           fullWidth
                           helperText={audienceAddFormik.touched.audienceName && audienceAddFormik.errors.audienceName}
                           label="Audience name"
                           margin="normal"
                           name="audienceName"
                           onBlur={audienceAddFormik.handleBlur}
                           onChange={audienceAddFormik.handleChange}
                           type="text"
                           value={audienceAddFormik.values.audienceName}
                           variant="outlined" />
              </Grid>

              <Grid item xs={12}>
                <FormControl sx={{ mb: 6 }} component="fieldset" variant="standard"
                             error={Boolean(audienceAddFormik.touched.thirdPartyIdType && audienceAddFormik.errors.thirdPartyIdType)}>
                  <Typography variant="body2">What kind of user identifiers are you uploading?</Typography>

                  <RadioGroup name="thirdPartyIdType"
                              onChange={(event) => {
                                audienceAddFormik.handleChange(event);
                              }}>
                    <FormControlLabel value="keyboard_user_id"
                                      label="Keyboard user IDs"
                                      control={<Radio />} />

                    <FormControlLabel value="player_id"
                                      label="My app's user IDs"
                                      control={<Radio  />} />
                  </RadioGroup>

                  <FormHelperText error={true}>{audienceAddFormik.errors.thirdPartyIdType}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  {audienceAddFormik.values.userIds && audienceAddFormik.values.userIds.length > 0 ? (
                    <>Upload another CSV</>
                  ) : (
                    <>Upload user IDs CSV</>
                  )}
                  <VisuallyHiddenInput type="file" onChange={handleCsvChange} />
                </Button>

                {audienceAddFormik.values.userIds &&  audienceAddFormik.values.userIds.length > 0 && (
                  <Box>
                    <List dense>
                      {audienceAddFormik.values.userIds.slice(0, 3).map(userId => (
                        <ListItem key={`user_example_${userId}`}>
                          <ListItemIcon>
                            <Person />
                          </ListItemIcon>
                          <ListItemText>
                            {userId}
                          </ListItemText>
                        </ListItem>
                      ))}

                      <ListItem key="user_example_dots">
                        <ListItemText>
                          ... ({audienceAddFormik.values.userIds.length} records)
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Box>
                )}

                <FormHelperText error={true}>{audienceAddFormik.errors.userIds}</FormHelperText>
              </Grid>
            </Grid>
          </form>
        </TabPanel>

        <TabPanel value={tabIndex} index={1} name="Audience Intersection" key="tab_panel_audience_intersection">
          <DialogContentText id="audience-add-modal-text" sx={{ mt: 4 }}>
            Alternatively, you can select two or more of your existing audiences to intersect in order to create a new result set containing the common users.
          </DialogContentText>

          <form noValidate onSubmit={audienceIntersectionFormik.handleSubmit}>
            <Grid container={true} spacing={6} sx={{ py: 6 }}>
              <Grid item xs={12}>
                <Typography variant="body2">What's the name of your new audience?</Typography>

                <TextField error={Boolean(audienceIntersectionFormik.touched.audienceName && audienceIntersectionFormik.errors.audienceName)}
                           fullWidth
                           helperText={audienceIntersectionFormik.touched.audienceName && audienceIntersectionFormik.errors.audienceName}
                           label="Audience name"
                           margin="normal"
                           name="audienceName"
                           onBlur={audienceIntersectionFormik.handleBlur}
                           onChange={audienceIntersectionFormik.handleChange}
                           type="text"
                           value={audienceIntersectionFormik.values.audienceName}
                           variant="outlined" />

                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard"
                             error={Boolean(audienceIntersectionFormik.touched.audienceUids && audienceIntersectionFormik.errors.audienceUids)}>
                  <FormLabel component="legend">Audiences to intersect</FormLabel>
                  <FormGroup>
                    {audiences.data.map(audience => (
                      <Tooltip key={`tooltip_${audience.uid}`} placement="right" title={canSelect(audience) ? `${audience.members_count} members` : "This audience is of a different type than the one already selected."}>
                        <FormControlLabel control={
                                              <Checkbox onChange={() => handleAudienceSelectionChanged(audience)}
                                                        checked={audienceIntersectionFormik.values.audienceUids.includes(audience.uid) ?? false}
                                                        disabled={!canSelect(audience)}
                                                        name={`audience_checkbox_${audience.uid}`} />
                                          }
                                          key={audience.uid}
                                          label={audience.name} />
                      </Tooltip>
                    ))}
                  </FormGroup>
                  <FormHelperText error={Boolean(audienceIntersectionFormik.touched.audienceUids && audienceIntersectionFormik.errors.audienceUids)}>
                    {audienceIntersectionFormik.touched.audienceUids && audienceIntersectionFormik.errors.audienceUids}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </form>

          {tabIndex === 1 && props.checkAudiencesIntersection.success && props.checkAudiencesIntersection.data.intersection_common_users > 0 && (
            <Paper elevation={0} sx={{ p: 4, bgcolor: 'primary.lightest' }}>
              <Typography variant="body2">The selected audiences have <b>{props.checkAudiencesIntersection.data.intersection_common_users}</b> common users.</Typography>
            </Paper>
          )}

          {tabIndex === 1 && props.checkAudiencesIntersection.success && props.checkAudiencesIntersection.data.intersection_common_users === 0 && (
            <Paper elevation={0} sx={{ p: 4, bgcolor: 'danger.light' }}>
              <Typography variant="body2">Your selected audiences have no common elements. Try selecting other options.</Typography>
            </Paper>
          )}
        </TabPanel>
      </DialogContent>

      {tabIndex === 0 && (
        <DialogActions sx={{ pb: 4, px: 4 }}>
          <Button color="tertiary" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Submit

            {props.createAudience.isLoading && (
              <CircularProgress sx={{ ml: 2 }} size="20px" color="secondary" />
            )}
          </Button>
        </DialogActions>
      )}

      {tabIndex === 1 && (
        <DialogActions sx={{ pb: 4, px: 4 }}>
          <Button color="tertiary" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={() => {
            if( !props.checkAudiencesIntersection.isLoading && !props.checkAudiencesIntersection.success ) {
              handleCheckIntersection();
            } else {
              handleCreateAudienceIntersection();
            }
          }}>
            {!props.checkAudiencesIntersection.isLoading && !props.checkAudiencesIntersection.success && (
              <Typography>Check Intersection</Typography>
            )}

            {props.checkAudiencesIntersection.isLoading && (
              <>
                <Typography>Checking intersection</Typography>
                <CircularProgress sx={{ ml: 2 }} size="20px" color="secondary" />
              </>
            )}

            {!props.checkAudiencesIntersection.isLoading && !props.intersectAudience.isLoading && props.checkAudiencesIntersection.success && props.checkAudiencesIntersection.data && (
              <Typography>Create intersection audience</Typography>
            )}

            {!props.checkAudiencesIntersection.isLoading && props.intersectAudience.isLoading && props.checkAudiencesIntersection.success && props.checkAudiencesIntersection.data && (
              <>
                <Typography>Create intersection audience</Typography>
                <CircularProgress sx={{ ml: 2 }} size="20px" color="secondary" />
              </>
            )}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default connect(stateToProps, actionToProps)(AudienceAdd);
import moment from "moment";
import momentDuration from "moment-duration-format";

import {Box, Typography, Chip, Paper, IconButton, useTheme} from "@mui/material";
import PropTypes from "prop-types";
import {CloudDownload} from "@mui/icons-material";
import {convertJSONtoCSV, downloadCSV} from "../../utils/csvDownload";

momentDuration(moment);

export const FORMAT_LONG = "FORMAT_LONG";
export const FORMAT_DURATION = "FORMAT_DURATION";
export const FORMAT_PERC = "FORMAT_PERC";

export const FORMAT_AMOUNT = "FORMAT_AMOUNT";

function toHuman(num) {
  const units = [
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "K" },
  ];

  for (let unit of units) {
    if (num >= unit.value) {
      let value = num / unit.value;
      if (Math.floor(value) === value) {
        return value.toFixed(0) + unit.symbol;
      } else if (Math.floor(value * 10) === value * 10) {
        return value.toFixed(1) + unit.symbol;
      } else {
        return value.toFixed(2) + unit.symbol;
      }
    }
  }

  if (num < 1) {
    return Number(num).toFixed(2);
  }

  return num.toString();
}

function toHumanAmount(num) {
  // Ensure the input is a number
  if (typeof num !== 'number') {
    throw new TypeError('Input must be a number');
  }

  // Format the number to 2 decimal places
  let formattedNum = num.toFixed(2);

  // Append the $ sign at the end
  return '$' + formattedNum;
}

function toHumanDuration(num) {
  return moment.duration(num, "seconds").format("d[d] h[h] m[m]");
}

function toHumanPercentage(num) {
  return Number(num / 100).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 2,
  });
}

export const FORMATTERS = {
  [FORMAT_LONG]: toHuman,
  [FORMAT_DURATION]: toHumanDuration,
  [FORMAT_PERC]: toHumanPercentage,
  [FORMAT_AMOUNT]: toHumanAmount,
};

function toPerc(num) {
  const perc = Number(num / 100).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 1,
  }).replace(',', '.');

  return num > 0 ? '+' + perc : perc;
}

function NumberStat({ value, label, delta, formatter, filters }) {
  const theme = useTheme();
  const download = () => {
    const downloadableData = {
      [label]: FORMATTERS[formatter](value).replace(',', '.'),
      change: toPerc(delta),
      ...filters,
      time: (new Date()).toDateString(),
    };

    const csvContent = convertJSONtoCSV(downloadableData);

    if (csvContent) {
      downloadCSV(downloadableData, label);
    }
  };

  return (
    <Paper sx={{ flexGrow: 1, p: 4, position: 'relative', '&:hover .download-icon': { visibility: 'visible' } }}>
      <Typography color="textPrimary" variant="body2" component="div">
        {label}
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h3"
          sx={{ fontWeight: "bold", flexGrow: 1 }}
          component="div"
        >
          {FORMATTERS[formatter](value).replace(',', '.')}
        </Typography>
        {typeof delta !== "undefined" && delta && (
          <Box>
            <Chip
              label={toPerc(delta)}
              sx={{
                backgroundColor: delta > 0 ? theme.palette.vis.counters.deltaPositiveBackground : theme.palette.vis.counters.deltaNegativeBackground,
                color: delta > 0 ? theme.palette.vis.counters.deltaPositiveText : theme.palette.vis.counters.deltaNegativeText
              }}
              size="small"
            />
          </Box>
        )}
      </Box>

      <IconButton aria-label="download" className="download-icon" sx={{ position: 'absolute', top: '-16px', right: '-16px', visibility: 'hidden' }}
                  onClick={download}>
        <CloudDownload />
      </IconButton>
    </Paper>
  );
}

NumberStat.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string,
  delta: PropTypes.number,
  formatter: PropTypes.oneOf([FORMAT_DURATION, FORMAT_LONG, FORMAT_PERC, FORMAT_AMOUNT]),
};

NumberStat.defaultProps = {
  label: null,
  delta: null,
  formatter: FORMAT_LONG,
};

export default NumberStat;

import React, {useMemo, useRef, useState} from "react";
import { Bar as VisBar } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { IconButton, Paper, Typography, useTheme} from "@mui/material";

import {CloudDownload} from "@mui/icons-material";
import {convertJSONtoCSV, downloadCSV} from "../../utils/csvDownload";
import {toHumanPercentage} from "../../utils/numbers";
import ResizeListener from "../../utils/ResizeListener";

const vMargin = 120;
const hMargin = 60;

export default function Bar({
  title,
  height,
  data,
  xLabel,
  yLabel,
  events = false,
  filters,
  sx,
}) {
  const theme = useTheme();
  const visContainerRef = useRef(null);
  const [visWidth, setVisWidth] = useState(450);

  const handleResize = () => {
    setVisWidth(visContainerRef.current.clientWidth - 24);
  };

  // bounds
  const xMax = visWidth - hMargin;
  const yMax = height - vMargin;

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        round: true,
        domain: data.map((d) => d.label),
        padding: 0.4,
      }),
    [xMax]
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...data.map((d) => d.value))],
      }),
    [yMax]
  );

  const download = () => {
    // Transform the data into the desired structure
    const transformedData = data.reduce((acc, x) => {
      acc[x.label] = toHumanPercentage(x.value);
      return acc;
    }, {});

    // Combine the transformed data with metadata
    const downloadableData = {
      ...transformedData,
      ...filters,
      time: (new Date()).toDateString()
    };

    const csvContent = convertJSONtoCSV(downloadableData);

    if (csvContent) {
      downloadCSV(downloadableData, title);
    }
  };

  return visWidth < 10 ? null : (
    <Paper sx={{ p: 4, width: "100%", display: "flex", flexDirection: "column", alignItems: "center", position: 'relative', '&:hover .download-icon': { visibility: 'visible'}, ...sx }}
           ref={visContainerRef} >
      {title && <Typography sx={{ fontWeight: "bold", alignSelf: "flex-start" }}>{title}</Typography>}
      <svg width={visWidth} height={height}>
        <Group top={vMargin / 4} left={hMargin}>
          <GridRows
            scale={yScale}
            width={xMax}
            height={yMax}
            stroke={theme.palette.text.tertiary}
          />
          {data.map((d) => {
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(d.value) ?? 0);
            const barX = xScale(d.label);
            const barY = yMax - barHeight;
            return (
              <VisBar
                key={`bar-${d.label}`}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill={theme.palette.vis.histogram.one}
              />
            );
          })}
        </Group>

        <AxisLeft
          scale={yScale}
          left={hMargin}
          top={vMargin / 4}
          labelProps={{ fill: theme.palette.text.primary }}
          tickLabelProps={{ fill: theme.palette.text.primary }}
          hideTicks={true}
          hideAxisLine={true}
          label={yLabel}
        />
        <AxisBottom
          left={hMargin}
          top={yMax + vMargin / 2}
          labelProps={{ fill: theme.palette.text.primary }}
          tickLabelProps={{ fill: theme.palette.text.primary }}
          scale={xScale}
          hideTicks={true}
          hideAxisLine={true}
          label={xLabel}
        />
      </svg>

      <IconButton aria-label="download" className="download-icon" sx={{ position: 'absolute', top: '-16px', right: '-16px', visibility: 'hidden' }}
                  onClick={download}>
        <CloudDownload />
      </IconButton>

      <ResizeListener onResize={handleResize} />
    </Paper>
  );
}
